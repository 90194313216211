<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-select
            @change="typeName"
            v-model="categoriesValue"
            class="w120 mb10 mr10"
            placeholder="商品分类"
        >
          <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.name"
              :value="item.goodstypeId"
          >
          </el-option>
        </el-select>
        <el-input
            class="w120 mb10 mr10"
            placeholder="商品名称"
            v-model="filterName"
        />

        <!--
        <el-select
          @change="putaway"
          v-model="shelvesValue"
          class="w120 mb10 mr10"
          placeholder="是否上架"
        >
          <el-option
            v-for="(item, index) in ['全部','是', '否']"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="distributionValue"
          @change="agency"
          class="w120 mb10 mr10"
          placeholder="是否分销"
        >
          <el-option
            v-for="(item, index) in ['是', '否']"
            :key="index"
            :label="item"
            :value="index"
          >
          </el-option>
        </el-select> -->
        <el-button type="primary" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <!-- <el-button class="ma mr10 mb10" @click="TPublic">批量上架</el-button>
        <el-button class="ma mr10 mb10" @click="FPublic">批量下架</el-button>
        <el-button class="ma mr10 mb10" @click="delAll">批量删除</el-button> -->
        <el-button class="ma" type="primary" @click="getRedactOrAdd()"
        >+添加商品
        </el-button
        >
      </div>
    </div>

    <commonTable
        :tableData="tableData"
        :total="total"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :loading="loading"
    >
      <template v-slot:table>
        <!-- <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50"
        ></el-table-column> -->

        <el-table-column prop="goodsId" align="center" width="100" label="商品编号"/>
        <el-table-column prop="goodPhoto" align="center" label="商品图片">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.goodPhoto"
                style="width: 100px; height: 80px;margin-bottom:-10px"
                :src="scope.row.goodPhoto"
            ></el-image>
            <p
                v-else
                style="width: 100px; height: 80px;line-height: 80px;margin-bottom:-10px"
            >
              暂无图片
            </p>
          </template>
        </el-table-column>
        <el-table-column
            prop="goodsName"
            align="center"
            label="商品名称"
            :show-overflow-tooltip="true"
        />
        <el-table-column
            prop="goodsTypeName"
            align="center"
            width="150"
            label="分类"
            show-overflow-tooltip
        />
        <el-table-column
            prop="maxPrice"
            align="center"
            width="150"
            label="价格"
            show-overflow-tooltip
        />
        <el-table-column prop="stockNum" width="100" align="center" label="总库存"/>
        <el-table-column prop="dealNum" width="100" align="center" label="总销量"/>
        <el-table-column prop="createTime" width="200px" align="center" label="发布时间">
          <template slot-scope="scope">
            {{ scope.row.createTime | getDataTimeSec }}
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，商品排序越靠前" placement="top-start">
              <el-input v-model="scope.row.sort" placeholder="数值越大，商品排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <!--  <el-table-column
            prop="distributionRatioOrAmount"
            align="center"
            label="分销比例/金额"
          /> -->

        <!-- <el-table-column prop="isPublic" align="center" label="是否上架">
          <template slot-scope="scope">
            <el-button
              @click="isPublic(scope.row)"
              v-if="scope.row.isPutOn"
              type="text"
              size="mini"
              >下架</el-button
            >
            <el-button
              @click="isPublic(scope.row)"
              type="text"
              v-else
              size="mini"
              >上架</el-button
            >
          </template>
        </el-table-column> -->

        <el-table-column align="center" :width="auctionState ? 400 : 300" label="操作">
          <template slot-scope="scope">
            <el-button plain size="mini" @click="getDetails(scope.row)">详情</el-button>
            <el-button type="primary" plain size="mini" @click="getRedactOrAdd(scope.row)">编辑</el-button>
            <customPopconfirm confirm-button-text="确定" cancel-button-text="取消" @confirm="del(scope.row)"
                icon="el-icon-info" icon-color="red" title="确认删除？">
              <el-button type="danger" size="mini" class="ma ml10 mb10" slot="reference" plain>删除</el-button>
            </customPopconfirm>
            <el-button @click="isPublic(scope.row)" size="mini" v-if="scope.row.isPutOn" class="ma ml10 mb10">下架</el-button>
            <el-button @click="isPublic(scope.row)" class="ma ml10 mb10" v-else size="mini">上架</el-button>
            <el-button @click="addAuction(scope.row)" class="ma ml10 mb10" v-if="auctionState" size="mini" >添加竞价</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
  // @ts-ignore
  import commonTable from "@/components/common/commonTable";
  import customPopconfirm from "@/components/common/customPopconfirm";
  import {getDataTimeSec} from "@/utils";
  // @ts-ignore
  import {delGoods, getAuctionSetting, getGoodsInfo, getGoodsTypeInfo, updateGoodsSort, updateOnline} from "@/api/coupon.js";

  export default {
    name: "productList",
    components: {
      commonTable,
      customPopconfirm
    },
    props: {
      jurisdiction: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        userInfo: this.$store.state.loginRoot.userInfo,
        options: [],
        categoriesValue: "全部", //商品分类
        shelvesValue: "", //是否上架
        distributionValue: "", //是否分销
        tableData: [],
        filterName: "", //搜索\
        tableDataF: [], //筛选
        delGoods: [],
        page: 1,
        loading: false, //加载中\
        total: 0,
        pageNum: 1,
        pageSize: 10,
        auctionState: false
      };
    },
    filters: {
      getDataTimeSec(val) {
        return getDataTimeSec(val);
      }
    },
    methods: {
      handleSelectionChange(value) {
        this.delGoods = value;
      },

      //批量上架
      TPublic() {
        if (this.delGoods.length > 0) {
          for (let i = 0; i < this.delGoods.length; i++) {
            const data = {
              goodsId: this.delGoods[i].goodsId,
              isPutOn: true,
            };
            updateOnline(data);
          }
          this.get();
        }
      },

      //上下架
      isPublic(row) {
        if (this.jurisdiction || this.userInfo.roleId) {
          const data = {
            goodsId: row.goodsId,
            isPutOn: !row.isPutOn,
          };
          updateOnline(data)
            .then(res => {
              if (res.code == 200) {
                this.$message.success(res.message)
              } else {
                this.$message.error(res.message)
              }
            });
          this.get();
        } else {
          this.$message({
            type: "warning",
            message: "暂无权限",
          });
          return
        }

      },

      //批量下架
      FPublic() {
        if (this.delGoods.length > 0) {
          for (let i = 0; i < this.delGoods.length; i++) {
            const data = {
              goodsId: this.delGoods[i].goodsId,
              isPutOn: false,
            };
            updateOnline(data);
          }
          this.get();
        }
      },

      //批量删除
      delAll() {
        this.delGoods.map((itme) => {
          delGoods({goodsId: itme.goodsId});
        });
        if (this.delGoods.length > 0) {
          this.$message({
            message: "操作成功",
            type: "success",
            center: true,
          });
        }
        this.get();
        this.delGoods = [];
      },

      //获取商品
      async get() {
        let param = {
          goodsName: this.filterName,
          goodstypeId: this.goodstypeId,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        this.loading = true;
        this.auctionState = await getAuctionSetting({});
        const {data} = await getGoodsInfo(param); //商品列表
        if (data) {

          let val = JSON.parse(data.pageInfo);
          console.log(val)
          this.tableData = val.list
          this.total = val.total
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].goodPhoto) {
              this.tableData[i].maxPrice = (this.tableData[i].maxPrice / 100).toFixed(2)
              this.tableData[i].goodPhoto = this.tableData[i].goodPhoto.split(
                ","
              )[0];
            }
          }
          this.tableDataF = this.tableData;
          this.loading = false;
        } else {
          this.tableData = [];
          this.loading = false;
        }
      },

      //搜索类名
      typeName(value) {
        this.goodstypeId = value

      },

      //搜索上架
      putaway(value) {
        if (value == 0) {
          this.tableData = this.tableDataF.filter((item) => item.isDistribution);
        } else {
          this.tableData = this.tableDataF.filter((item) => !item.isDistribution);
        }
      },

      //搜索分销
      agency(value) {
        if (value == 0) {
          this.tableData = this.tableDataF.filter((item) => item.isPublic);
        } else {
          this.tableData = this.tableDataF.filter((item) => !item.isPublic);
        }
      },

      //商品详情
      getDetails(row) {
        this.$router.push({
          path: `/goodsDetails`,
          query: {details: JSON.stringify(row)},
        });
      },

      //商品管理
      getRedactOrAdd(row) {

        if (this.userInfo.roleId || this.jurisdiction) {
          if (row) {
            this.$router.push({
              path: "/redactOrAdd",
              query: {
                id: row.goodsId,
              },
            });
          } else {
            this.$router.push({
              path: "/redactOrAdd",
            });
          }
        } else {
          this.$message({
            type: "warning",
            message: "暂无权限",
          });
          return
        }
      },
      //输入框搜索
      async filterNames() {
        this.get();
      },
      //删除弹窗
      del(row) {
        if (this.jurisdiction || this.userInfo.roleId) {
          delGoods({goodsId: row.goodsId})
            .then(() => {
              this.$message({
                message: "操作成功",
                type: "success",
                center: true,
              });
              this.get();
            })
            .catch(() => {
              this.$message({
                message: "操作失败",
                type: "warning",
                center: true,
              });
            });
        } else {
          this.$message({
            type: "warning",
            message: "暂无权限",
          });
          return
        }
      },
      //当前页变更
      handleCurrentChange(num) {
        this.pageNum = num;
        this.get();
      },
      //当前页查询数据条数变更
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.currentPage = 1;
        this.get();
      },
      async saveSort(row) {
        if (row && row.sort != "") {
          console.log(row)
          const result = await updateGoodsSort(row);
          console.log("result:", result)
          this.$message({
            type: "info",
            message: "保存成功",
          });
        } else {
          this.$message({
            type: "error",
            message: "排序不能为空",
          });
        }
      },
      // 添加竞价
      addAuction() {
        // TODO
      }
    },
    //生命周期
    async created() {
      this.get();
      //类型筛选
      const datas = await getGoodsTypeInfo({}); //获取分类数据
      Object.assign(this.options, datas.data);
      this.options.unshift({name: "全部"});
    },
  };
</script>

<style lang="scss" scoped></style>
